<template>

<div class="tickets">

	<app-panel-item v-on:click="$emit('click', ticket)" v-for="ticket in tickets" :key="ticket.id" :text="ticket.badge.name" :subtext="ticketSubtext(ticket)" :iconAfter="ticketIcon(ticket)" :data-theme="ticketTheme(ticket)" />

</div>

</template>

<script>

export default {

	props: ['tickets'],

	methods: {

		ticketSubtext: function(ticket) {

			if (ticket.scanned) {

				return 'Scanned at ' + this.$filter('formatDate', ticket.scanned, 'HH:mm')

			} else {

				return '#' + ticket.badge.reference_random

			}

		},

		ticketTheme: function(ticket) {

			if (ticket.scanned) {

				return 'green'

			} else {

				return 'red'

			}

		},

		ticketIcon: function(ticket) {

			if (ticket.scanned) {

				return 'tickOnly'

			} else {

				return 'crossOnly'

			}

		}

	}

}

</script>

<style scoped>

.tickets >>> .item {
	padding: 5px 12px;
	border-bottom: 1px solid #eee;
    margin: 0px;
}

.tickets >>> .item:last-child {
	border-bottom: 0px;
}

.is-desktop .tickets >>> .item:hover {
    background-color: rgba(0, 0, 0, 0.15);
	color: #333;
    border-radius: 0px;
    margin: 0px;
	padding: 5px 12px;
}

.tickets >>> .item .item-icon {
    background-color: rgba(0, 0, 0, 0.15);
	color: #4d5b6e;
	font-size: 14px;
	width: 24px;
	height: 24px;
	line-height: 26px;
	border-radius: 50%;
}

.tickets >>> .item[data-theme="green"] .item-icon {
    color: #fff;
	background-color: #4faa80;
}

.tickets >>> .item[data-theme="yellow"] .item-icon {
    color: #fff;
	background-color: #ffbe7a;
}

.tickets >>> .item[data-theme="red"] .item-icon {
    color: #fff;
	background-color: #c55b5b;
}

.tickets >>> .item-text {
    color: #333;
	font-size: 16px;
	font-weight: 300;
}

.tickets >>> .item-text small {
	margin-top: 4px;
	opacity: 0.75;
}

.tickets >>> .item:first-child {
    margin-top: 0px;
}

</style>
